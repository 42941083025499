<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">Companies</h2>
          </div>
          <div class="col text-end">
              <a class="btn btn-outline-dark me-2" href="/export/fields/company">Export</a>
              <button class="btn btn-outline-dark" @click="showModal">import</button>
          </div>
      </div>
        <div class="t-layout">
          <div class="row">
              <div v-if="$store.state.permittedActions.includes(111)" class="col-md-6">
                  <div class="card">
                <div class="card-header">
                  <flash-message class="myCustomClass"></flash-message>
                </div>
                <div class="card-body">
                    <form @submit.prevent="onSubmit($event)">
                        <div class="mb-3">
                            <label class="form-label">Company</label>
                            <input
                                class="form-control"
                                v-model="$v.form.company.$model"
                                :validator="$v.form.company"
                            />
                            <span
                                class="error "
                                v-if="$v.form.company.$error == true"
                                >Company name is required </span
                            >
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Start Date</label>
                            <datepicker
                                typeable="true"
                                placeholder="Select Date"
                                class="form-control"
                                v-model="$v.form.start_date.$model"
                                :validator="$v.form.start_date"
                                :format="customFormatter"
                            ></datepicker>
                            <span
                                class="error "
                                v-if="$v.form.start_date.$error == true"
                                >Company start date is required </span
                            >
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-outline-primary" >Save</button>
                        </div>
                    </form>
                </div>
              </div>
              </div>
              <div :class="$store.state.permittedActions.includes(111) ? 'col-md-6': 'col-md-12'">
                <div v-if="this.$store.state.loader">
                    <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
                </div>
                <div v-else class="card">
                <div class="card-header">
                </div>
                <div class="card-body">

                  <b-table
                    class="table table-custom"
                    :items="companies"
                    :fields="fields"
                    show-empty
                    sticky-header
                  >
                  <template v-slot:cell(start_date)="data">
                      {{ customFormatter(data.item.start_date) }}
                  </template>
                  <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                          <div class="d-flex justify-content-md-around">
                          <a v-if="$store.state.permittedActions.includes(113)" class="button button1" title="Edit" @click.prevent="editCompany(data.item.id)"><i class="icon-pencil"></i></a>
                          <a v-if="$store.state.permittedActions.includes(112)" class="button button1" title="Delete" @click.prevent="deleteCompany(data.item.id)"><i class="icon-bin"></i></a>
                          </div>
                      </div>
                  </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios';
import moment from 'moment';
import Datepicker from "vuejs-datepicker";

export default {
    components: { Datepicker},
   data(){
       return {
           isModalVisible: false,
           edit: false,
           form: {
               id: null,
               company: null,
               start_date: null
           },
           fields:[
               {
                key: 'company',
                   tdAttr: { 'custom-label'  : "Company:"}
               },
               {
                key: 'start_date',
                   tdAttr: { 'custom-label'  : "Start Date:"},
                   label: 'Start Date'
               },
               {
                   key: 'action',
                   tdAttr: { 'custom-label'  : "Action:"}
               }
           ]
       }
   },
   validations: {
       form: {
           id:{},
           company: {
               required
           },
           start_date: {
               required
           }
       }
   },
   computed:{
       companies(){
           return this.$store.state.companiesData;
       }
   },
   created(){
       this.$store.dispatch('loadCompanies');
   },
   methods:{
       customFormatter(date) {
        return moment(date).format('yyyy-MM-DD');
        },
       showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
       onSubmit(event){
            this.$v.$touch();
            if (this.$v.$invalid) return;
            let url = '';
            if(this.edit == false){
                url = '/fields/company/add';
            }
            else{
                url = '/fields/company/update';
            }
            let formData = new FormData();
            if(this.edit == true){ formData.append('id',this.form.id) }
            formData.append('company',this.form.company);
            formData.append('start_date',this.form.start_date);
            axios
                .post(url, formData)
                .catch(({ response }) => {
                this.flashMessage.error({
                    message: "Something went wrong!",
                    time: 3000,
                });
                })
                .then(({ data }) => {
                if (data.success) {
                    this.form.company = null;
                    this.form.start_date = null;
                    this.form.id = null;
                    this.flashMessage.success({
                    message: data.success,
                    time: 3000,
                    });
                    this.$store.dispatch('loadCompanies');
                } else {
                    this.flashMessage.error({
                    message: data.error,
                    time: 3000,
                    });
                }
                });
       },
       editCompany(id){
           let companyDetail = this.companies.filter((company) => company.id == id)[0];
           this.edit = true;
           this.form.id = companyDetail.id;
           this.form.company = companyDetail.company;
           this.form.start_date = companyDetail.start_date;
       },
       deleteCompany(id){
           if(confirm('Are you sure?')){
                axios.get("/fields/company/delete/"+id)
                    .catch(({ response }) => {
                    this.flashMessage.error({
                        message: "Something went wrong!",
                        time: 3000,
                    });
                    })
                    .then(({ data }) => {
                    if (data.success) {
                        this.form.company = null;
                        this.flashMessage.success({
                        message: data.success,
                        time: 3000,
                        });
                        this.$store.dispatch('loadCompanies');
                    } else {
                        this.flashMessage.error({
                        message: data.error,
                        time: 3000,
                        });
                    }
                    });
            }
       }
   }
}
</script>
